
import {
  defineComponent,
  ref,
  unref,
  onMounted,
  watch
} from 'vue';
import { useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';

import { Rule } from 'async-validator';

import useFormErrors from '@/utils/validate';
import { useCreateRecommendKeyword } from '@/composables/api';
import { CREATED_SUCCESSFULLY_TEXT, CreateRecommendKeywordData, getWorks, MAX_PER_PAGE } from '@/services/api';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: CreateRecommendKeywordData = {
  keyword: {
    'zh-TW': '',
    'en-US': ''
  },
  workId: null
};

const rules = {
  keyword: {
    'zh-TW': [
      {
        message: '此為必填欄位',
        required: true,
        trigger: 'blur'
      }
    ],
    'en-US': [
      {
        message: '此為必填欄位',
        required: true,
        trigger: 'blur'
      }
    ]
  },
  workId: [
    {
      required: true,
      message: '此為必填欄位',
      trigger: 'change'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup() {
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });

    const works = ref();
    const keyword = ref();

    const { isLoading: isCreating, mutate: create } = useCreateRecommendKeyword();
    const { formErrors, bindFormItemError } = useFormErrors();

    const fetchWorks = async() => {
      works.value = (await getWorks({ query: { pageSize: MAX_PER_PAGE, keyword: keyword.value } })).data;
    };

    const getKeyword = (e: string) => {
      keyword.value = e;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

              formValues.value = DEFAULT_FORM_VALUES;
              router.replace({
                name: 'list_recommend-keywords'
              });
            },
            onError(error: any) {
              ElMessage.error({
                message: error.response?.data.message,
                type: 'error'
              });
              formErrors.value = error.response?.data.message;
            }
          });
        }
      });
    };

    onMounted(() => {
      fetchWorks();
    });

    watch(keyword, () => {
      fetchWorks();
    });

    return {
      formValues,
      formRef,
      rules,
      isCreating,
      submitForm,
      formErrors,
      bindFormItemError,
      works,
      getKeyword
    };
  }
});
